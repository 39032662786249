import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import { Container, Row, Col } from 'react-grid-system';
import CallToAction from '@interness/theme-sonora/src/components/CallToAction/CallToAction';
import { BsTools } from 'react-icons/bs';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Heading = makeShortcode("Heading");
const Wrapper = makeShortcode("Wrapper");
const List = makeShortcode("List");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <hr></hr>
    <Heading icon={<BsTools mdxType="BsTools" />} mdxType="Heading">Individueller Service</Heading>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
  <Container fluid mdxType="Container">
    <Row mdxType="Row">
      <Col md={6} mdxType="Col">
        <h2>Armbanduhren</h2>
        <List mdxType="List">
          <li>mechanische Uhren / Handaufzug</li>
          <li>Quarzarmbanduhren</li>
          <li>Automatikuhren</li>
          <li>Batteriewechsel</li>
          <li>Komplette Revisionen</li>
          <li>Taschenuhren</li>
          <li>Glasersatz</li>
          <li>Leder-, Metall-, Kunststoffuhrarmbänder</li>
          <li>Bandanpassung</li>
        </List>
      </Col>
      <Col md={6} mdxType="Col">
        <h2>Goldschmiedereparaturen</h2>
        <List mdxType="List">
          <li>Goldschmiedereparaturen aller Art</li>
          <li>Kette und Ösen zulöten, Verschlüsse ersetzen</li>
          <li>Ringgrößen ändern</li>
          <li>Perlenketten neu aufziehen oder knoten</li>
          <li>Trauringumarbeitung</li>
          <li>Schmuck reinigen und aufarbeiten</li>
          <li>Gravuren in Ringe, Anhänger, Uhren, usw.</li>
        </List>
      </Col>
    </Row>
    <Row mdxType="Row">
      <Col md={6} mdxType="Col">
        <h2>Großuhren</h2>
        <List mdxType="List">
          <li>Heim – und Tischuhren, Jahresuhren</li>
          <li>Standuhren</li>
          <li>Regulatoren</li>
        </List>
      </Col>
      <Col md={6} mdxType="Col">
        <h2>Altgold- und Altsilberankauf</h2>
        <List mdxType="List">
          <li>Wir kaufen Altgold aus vielen, unterschiedlichen Bereichen an:</li>
          <li>Zahngold, Goldnuggets, Altgold aus Schmuckstücken und Münzen</li>
          <li>Wir prüfen fachkundig und unkompliziert und vergüten nach aktuellen Edelmetallkursen</li>
          <li>Altgoldankauf ist Vertrauenssache</li>
        </List>
      </Col>
    </Row>
  </Container>
    </Wrapper>
    <hr></hr>
    <CallToAction mdxType="CallToAction" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      